<template>
  <div
      class="min-w-0 bg-pure-white flex flex-col shadow cursor-pointer"
      @click="goToProductDetails"
  >
    <div class="h-190 flex items-center justify-center">
      <img
          class="h-95"
          :src="product.data.image"
      >
    </div>
    <div class="h-190 bg-white m-2 px-26 pt-16">
      <p class="text-faded-red font-sans-bold text-lg">{{ product.id }}</p>
      <p class="text-sm mt-7">
        {{ product.data[$i18n.locale].subtitle }}
      </p>
      <div class="flex flex-col mt-20">
        <div class="flex flex-row items-center text-sm">
          <IconSoundWave class="text-charcoal-grey" />
          <p class="mr-30">Rw {{ product.data.rw }} dB</p>
          <p v-if="product.data.lnw !== 0">Lnw {{ product.data.lnw }} dB</p>
        </div>
        <div class="flex flex-row items-center text-sm">
          <IconFireFlame class="text-charcoal-grey flex-no-shrink" />
          <p class="truncate">{{ fireResistanceFilters }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import IconFireFlame from '@/components/icon/IconFireFlame';
import IconSoundWave from '@/components/icon/IconSoundWave';


export default {
  components: {
    IconFireFlame,
    IconSoundWave,
  },
  props: {
    product: {
      type: Object,
      validator(newValue) {
        return Object.prototype.hasOwnProperty.call(newValue, 'id')
          && Object.prototype.hasOwnProperty.call(newValue, 'data');
      },
    },
  },
  computed: {
    fireResistanceFilters() {
      return this.product.data.fireResistanceFilters.join(' ');
    },
  },
  methods: {
    goToProductDetails() {
      this.$router.push({
        name: 'ProductDetailsView',
        params: {
          productId: this.product.id,
          productType: this.product.data.productType,
          allowBack: true,
          previousRouteName: this.$route.name,
        },
      });
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
