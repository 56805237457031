<template>
  <div class="product-grid">
    <ProductGridItem
        v-for="(product, index) in products"
        :key="index"
        :product="product"
    />
  </div>
</template>


<script>
import ProductGridItem from '@/components/product/ProductGridItem';


export default {
  props: {
    products: {
      type: Array,
      default() {
        return [];
      },
    },
    filterIsVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProductGridItem,
  },
  mounted() {
    // const grid = document.querySelector('.grid');
    // const { animateCSSGrid } = window;
    //
    // animateCSSGrid.wrapGrid(grid, { duration: 500 });
  },
};
</script>


<style lang="scss" scoped>
.product-grid {
  display: grid;
  grid-gap: 20px 20px;
}
</style>
