<template>
  <div>
    <ViewHeadSection
        background-image-src="/images/placeholders/bookmarks.jpg"
        class="fixed w-full"
    >
      <div>
        <p
            v-line-clamp:36="2"
            class="w-285 tablet-landscape:w-302 font-sans-bold text-xl text-pure-white uppercase"
        >
          {{ headSectionTitle }}
        </p>
      </div>
    </ViewHeadSection>
    <div class="h-full flex background-gradient">
      <div class="bookmark-lists-panel fixed flex flex-col w-242 ml-40">
        <div class="flex-1 overflow-y-auto">
          <p class="font-sans-bold text-base text-charcoal-grey uppercase mt-24 mb-30">
            {{ $t('bookmarks.lists_title_text') }}
          </p>
          <ul class="list-reset">
            <li
                v-for="(bookmarkList, index) in bookmarkListOptions"
                :key="`bookmark-list-${index}`"
                tabindex="0"
                class="bookmark-list flex items-center justify-between mb-23 cursor-pointer
                outline-none"
                :class="{ 'is-selected': uiSelectedBookmarkList.id === bookmarkList.id }"
                @click="onBookmarkListPress(bookmarkList.id || '', bookmarkList.name)"
                @keydown.space="onBookmarkListPress(bookmarkList.id || '', bookmarkList.name)"
            >
              <p class="label text-style-7 truncate mr-20">
                {{ bookmarkList.name }}
              </p>
              <p class="count text-base text-cool-grey">
                {{ bookmarkList.products.length }}
              </p>
            </li>
            <li
                tabindex="0"
                class="bookmark-list flex items-center flex-start text-style-7 mb-23 select-none
                cursor-pointer outline-none hover:text-faded-red"
                @click.stop="onNewBookmarkListPress"
                @keydown.space.stop="onNewBookmarkListPress"
            >
              <IconCross
                  :rotation-angle="45"
                  class="mr-15"
              />
              <p>
                {{ $t('bookmarks.new_list_text') }}
              </p>
            </li>
          </ul>
        </div>
        <ul class="list-reset pt-27 mb-30 border-t border-white select-none">
          <li class="mb-23">
            <p
                :tabindex="uiSelectedBookmarkList.id === '' ? -1 : 0"
                class="text-style-7 cursor-pointer outline-none"
                :class="{
                    'cursor-not-allowed': uiSelectedBookmarkList.id === '',
                    'hover:text-faded-red': uiSelectedBookmarkList.id !== '',
                }"
                @click.stop="onRenameBookmarkListPress"
                @keydown.space.stop="onRenameBookmarkListPress"
            >{{ $t('bookmarks.rename_list_text') }}</p>
          </li>
          <li>
            <p
                :tabindex="uiSelectedBookmarkList.id === '' ? -1 : 0"
                class="text-style-7 cursor-pointer outline-none"
                :class="{
                    'cursor-not-allowed': uiSelectedBookmarkList.id === '',
                    'hover:text-faded-red': uiSelectedBookmarkList.id !== '',
                }"
                @click.stop="onDeleteBookmarkListPress"
                @keydown.space.stop="onRenameBookmarkListPress"
            >{{ $t('bookmarks.delete_list_text') }}</p>
          </li>
        </ul>
      </div>
      <div class="bookmarked-products flex-1 my-40 px-40 z-20">
        <Transition
            name="fade-in-linear"
            type="out-in"
        >
          <div
              v-if="!selectedBookmarkList.products.length"
              class="h-510 m-auto max-w-462 flex flex-col justify-between bg-pure-white p-40 shadow"
          >
            <p class="text-style-2">
              {{ $t('bookmarks.empty_list.main_text') }}
            </p>
            <div>
              <p class="font-sans-bold text-base text-charcoal-grey uppercase mb-30">
                {{ $t('bookmarks.empty_list.description_text') }}
              </p>
              <RouterLink
                  :to="{ name: 'AssemblySearchView', params: { searchType: 'preset' } }"
                  tag="div"
                  class="flex items-center mb-25 cursor-pointer"
              >
                <span class="text-style-7">
                  {{ $t('bookmarks.empty_list.predefined_search_text') }}
                </span>
                <IconArrow
                    class="text-faded-red ml-10"
                />
              </RouterLink>
              <RouterLink
                  :to="{ name: 'AssemblySearchView', params: { searchType: 'manual' } }"
                  tag="div"
                  class="flex items-center cursor-pointer"
              >
                <span class="text-style-7">
                  {{ $t('bookmarks.empty_list.manual_search_text') }}
                </span>
                <IconArrow
                    class="text-faded-red ml-10"
                />
              </RouterLink>
            </div>
          </div>
          <div
              v-else
              class="mx-40"
          >
            <ProductGrid
                :products="selectedBookmarkList.products"
                :class="['grid-template-columns-1', 'tablet-landscape:grid-template-columns-2']"
            />
          </div>
        </Transition>
      </div>
    </div>

    <BookmarkModal />
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import IconCross from '@/components/icon/IconCross';
import IconArrow from '@/components/icon/IconArrow';
import ViewHeadSection from '@/components/view/ViewHeadSection';
import BookmarkModal from '@/components/bookmark/BookmarkModal';
import ProductGrid from '@/components/product/ProductGrid';


export default {
  components: {
    IconCross,
    IconArrow,
    ViewHeadSection,
    BookmarkModal,
    ProductGrid,
  },
  data: () => ({
    products: [
      { title: '', de: { subtitle: '' } },
      { title: '', de: { subtitle: '' } },
      { title: '', de: { subtitle: '' } },
      { title: '', de: { subtitle: '' } },
      { title: '', de: { subtitle: '' } },
      { title: '', de: { subtitle: '' } },
      { title: '', de: { subtitle: '' } },
    ],
  }),
  computed: {
    ...mapState('ui', {
      uiSelectedBookmarkList: 'selectedBookmarkListView',
    }),
    ...mapState('user', {
      userFirstName: 'firstName',
    }),
    ...mapGetters('user/bookmarks', {
      bookmarkListOptions: 'bookmarkListOptions',
    }),
    headSectionTitle() {
      return this.$t('bookmarks.title_text', { userName: this.userFirstName });
    },
    selectedBookmarkList() { // TODO: Rename and refactor using the bookmarks Getter
      if (!this.bookmarkListOptions) return { name: '', products: [] };

      const selectedBookmarkListItem = this.bookmarkListOptions
        .find(bookmarkListOption => bookmarkListOption.id === this.uiSelectedBookmarkList.id);

      // If ID is not available (deleted) select the general list
      if (!selectedBookmarkListItem) { // TODO: Remove from computed
        this.updateSelectedBookmarkList({ id: '', name: '' });
        return { name: '', products: [] };
      }
      return selectedBookmarkListItem;
    },
  },
  methods: {
    ...mapActions('ui', {
      updateModalState: 'updateModalState',
      updateBookmarkFormData: 'updateBookmarkFormData',
      updateSelectedBookmarkList: 'updateSelectedBookmarkList',
      updateSelectedBookmarkListView: 'updateSelectedBookmarkListView',
    }),
    onBookmarkListPress(id, name) {
      this.updateSelectedBookmarkListView({ id, name });
    },
    onNewBookmarkListPress() {
      this.updateBookmarkFormData({ name: '' }).then(() => {
        this.updateModalState({ isVisible: true, type: 'new-bookmark-list' });
      });
    },
    onRenameBookmarkListPress() {
      if (this.uiSelectedBookmarkList.id === '') return;

      this.updateBookmarkFormData({ name: this.selectedBookmarkList.name }).then(() => {
        this.updateModalState({ isVisible: true, type: 'rename-bookmark-list' });
      });
    },
    onDeleteBookmarkListPress() {
      if (this.uiSelectedBookmarkList.id === '') return;

      this.updateModalState({ isVisible: true, type: 'delete-bookmark-list' });
    },
  },
};
</script>


<style lang="scss" scoped>
@import '../styles/variables';

.background-gradient {
  background: linear-gradient(to right, config('colors.pure-white'), config('colors.white'));
}

.bookmark-lists-panel {
  top: 140px + $sticky-nav-top-h;
  bottom: $sticky-nav-bottom-h;

  .bookmark-list {
    transition: color .15s; // TODO: Extract to transitions.scss

    .label,
    .count {
      transition: color .15s; // TODO: Extract to transitions.scss
    }

    &.is-selected .label,
    &.is-selected .count {
      @apply text-faded-red;
    }

    &:hover .label,
    &:hover .count {
      @apply text-faded-red;
    }
  }
}

.bookmarked-products {
  margin-left: 242px + (40px * 2);
}
</style>
